@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap");

body {
  font-family: Roboto, sans-serif;
  font-size: 1rem;
  line-height: 1.5;

  padding: 0;
  margin: 0;

  color: #212529;

  height: 100%;

  text-rendering: optimizelegibility !important;
  -webkit-font-smoothing: antialiased !important;
}

a,
a:hover {
  cursor: pointer;
}

p,
label {
  margin: 0;
}

li,
ul {
  list-style: none;
}

:root {
  --blue-600: #304ffd;

  --grey200: #f8f8f8;
  --grey300: #f2f2f2;
  --grey500: #cdcdcd;
  --grey600: #bdbdbd;
  --grey700: #808080;

  --greyTextLight: #595f69;
  --greyTextDark: #3f434a;

  --orangeDefault500: #fb8209;
  --orangeDefault600: #e6780b;

  --green500: #219653;

  --red-500: #dc3545;
}

.alternativa-correta {
  color: var(--green500);
}

.c-sidebar .c-sidebar-brand {
  padding: 10px 0px;
}

.mb-footer {
  margin-bottom: 150px;
}

.d-contents {
  display: contents;
}

.alert-msg {
  position: fixed;
  top: 100px;
  padding: 9px 20px;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  z-index: 1060;
  margin: 0px auto;
  width: 200px;
  text-align: center;
  left: 0;
  right: 0;

  &.success {
    color: #155724;
    background-color: #d4edda;
    border-color: #c3e6cb;
  }

  &.error {
    color: #721c24;
    background-color: #f8d7da;
    border-color: #f5c6cb;
  }

  &.info {
    color: #856404;
    background-color: #fff3cd;
    border-color: #ffeeba;
  }
}

.open-alert {
  .alert-msg {
    animation: fadein 0.5s;
    -moz-animation: fadein 0.5s; /* Firefox */
    -webkit-animation: fadein 0.5s; /* Safari and Chrome */
    -o-animation: fadein 0.5s; /* Opera */
  }

  @keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  @-moz-keyframes fadein {
    /* Firefox */
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  @-webkit-keyframes fadein {
    /* Safari and Chrome */
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  @-o-keyframes fadein {
    /* Opera */
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
}

select {
  -webkit-appearance: none;
  width: 100%;
  padding: 12px 20px;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 6px;
  box-sizing: border-box;
}

.select {
  position: relative;
}

.select::before,
.select::after {
  content: "";
  position: absolute;
  top: 50%;
  border-top: 2px solid #00000073;
  width: 10px;
}

.select::before {
  right: 26px;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.select::after {
  right: 20px;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.close-alert {
  .alert-msg {
    animation: fadeout 0.5s;
    -moz-animation: fadeout 0.5s; /* Firefox */
    -webkit-animation: fadeout 0.5s; /* Safari and Chrome */
    -o-animation: fadeout 0.5s; /* Opera */
    opacity: 0;
  }

  @keyframes fadeout {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }
  @-moz-keyframes fadeout {
    /* Firefox */
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }
  @-webkit-keyframes fadeout {
    /* Safari and Chrome */
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }
  @-o-keyframes fadeout {
    /* Opera */
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }
}

/* TABELAS */

.prova_lote .table-responsive {
  height: 440px;
  overflow-x: auto;

  background: rgba(0, 0, 21, 0.05);

  /* width */
  &::-webkit-scrollbar {
    width: 8px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #8a9099;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}

.table-responsive {
  overflow-x: unset;

  .table {
    &.lote tr {
      text-align: center;
    }

    background: #fff;
    min-height: 320px;

    thead {
      background: linear-gradient(to bottom, #bdbdbd, var(--grey500));
    }
  }
}

/* FIM TABELAS */

.c-sidebar-minimized img {
  width: 100%;
}

.card {
  background-color: transparent;
  border-color: transparent;
}

.card-header {
  p {
    margin-bottom: 0px;
  }

  small {
    display: block;
    height: 20px;
  }
}

.modal-header {
  border: none;
}

.modal-body,
.card-body {
  label {
    cursor: pointer;
  }

  #logo {
    opacity: 0;
    position: absolute;
    z-index: -1;
  }
}

.image-input {
  border: 1px dashed #d7d7d7;
  height: 200px;
  width: 200px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 100%;
  }
}

.link-back {
  cursor: pointer;
  margin-bottom: 20px;
  font-weight: bold;

  label {
    cursor: pointer;
    color: #091e42;
  }
}

.pull-right {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

.btn-link,
.btn-inherit {
  border: none;
  background: none;
}

.center-loading {
  text-align: center;
}

.validation-advice {
  color: #cf3636;
  font-size: 12px;
}

.none {
  display: none;
}

.search {
  max-height: 160px;
  position: absolute;
  display: block;
  z-index: 2000;
  overflow: auto;
  width: 93%;
  margin-top: 4px;
  height: auto;
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  background-clip: padding-box;
  border: 1px solid;
  color: #768192;
  background-color: #fff;
  border-color: #d8dbe0;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  li {
    display: flex;
    margin: 10px;

    span {
      cursor: pointer;
    }

    button {
      border: none;
      background: none;
    }

    span:hover {
      cursor: pointer;
      color: #000;
      transition: 2s;
    }
  }
}

.search.focus {
  z-index: 2001;
}

// Ínicio Custom Select

.custom-select-search {
  small {
    text-transform: capitalize;
  }

  .show-validation-advice + .select-search {
    color: #768192;
    background-color: #fff;
    border-color: #958bef;
    outline: 0;
    box-shadow: 0 0 4px 2px #958bef;
  }

  .select-search {
    width: 100%;
    padding: 0px !important;

    ul {
      padding: 10px;

      li {
        padding: 0px !important;
      }
    }
  }

  .select-search__input {
    padding: 0 70px 0 16px;
    border: 1px solid #000000;
  }

  .select-search.is-loading:not(.select-search--multiple) .select-search__value::after {
    transform: rotate(45deg);
    border-right: 1px solid #000;
    border-bottom: 1px solid #000;
    pointer-events: none;
    background-image: inherit;
  }

  .close-select-search {
    background: none;
    border: none;
    position: absolute;
    top: 35px;
    right: 60px;
    z-index: 1;
    text-align: center;
    justify-content: center;
    align-items: center;
    padding: 0px;
    margin: 0px;
    display: none;
    animation: fadein 0.5s;
    -moz-animation: fadein 0.5s; /* Firefox */
    -webkit-animation: fadein 0.5s; /* Safari and Chrome */
    -o-animation: fadein 0.5s; /* Opera */
    svg {
      margin: 0px;
      padding: 0px;
    }

    &:active,
    &:focus {
      border: none;
      outline: none;
    }

    &.show {
      display: none;
    }

    @keyframes fadein {
      from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
    }
    @-moz-keyframes fadein {
      /* Firefox */
      from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
    }
    @-webkit-keyframes fadein {
      /* Safari and Chrome */
      from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
    }
    @-o-keyframes fadein {
      /* Opera */
      from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
    }
  }
}

// Fim Custom Select

.ml-1 {
  margin-left: 1px;
}

.c-sidebar-nav-item a > i {
  display: flex;
  flex: 0 0 56px;
  margin-left: -1rem;
  text-align: center;
  justify-content: center;
}

.arquivo-info {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  flex: 1;
  align-items: center;
  width: 100%;

  .div-trash {
    padding: 20px;
  }
}

.btn-groups {
  display: flex;
  flex-direction: row;
  align-items: center;

  span {
    margin: 0px 5px;
    font-weight: bold;
  }

  .select-file {
    i {
      margin-right: 5px;
    }

    font-weight: bold;
    align-items: center;
    justify-content: space-between;
    text-align: center;
    display: flex;
  }

  .select {
    width: 100px;
  }
}

.grid-estruturar {
  display: flex;
  justify-content: space-between;

  > .html-content-parser {
    height: 1000px;
    overflow-y: scroll;
    width: 40%;

    span.new-line {
      opacity: 0;
    }
  }

  > .html-content-parsed {
    //width: 67%;

    div {
      margin-bottom: 10px;
    }

    textarea {
      width: 100%;
      display: block;
      margin-bottom: 10px;
    }

    .referencia {
      background-color: rgb(242, 241, 163);
      font-size: 15px;

      .titulo,
      .autoria {
        text-align: center;
        font-weight: bold;
        margin-top: 10px;
      }
    }

    .questao {
      background: rgb(143, 202, 239);
      font-size: 15px;
      padding: 10px 20px;

      img {
        width: auto;
      }
    }
  }
}

pre {
  // white-space: break-spaces;
  img {
    width: auto;
  }
}

.cursor-pointer {
  cursor: pointer;

  &:disabled {
    opacity: 0.5;
    cursor: wait;
  }
}

.cursor-not-allowed {
  cursor: not-allowed;

  &:disabled {
    opacity: 0.5;
    cursor: wait;
  }
}

.cursor-wait {
  cursor: wait;
}

.form-group {
  display: flex;
  justify-content: space-between;
}

.circular-progress {
  color: var(--blue-600);
}

.form-dados-basico {
  background: #fff;
  width: 100%;
  padding: 1.25rem;

  .form-control {
    border-color: #000000;
    color: #212529;
  }
}

.text-decoration-none {
  text-decoration: none;
}

.text-reticencia {
  width: 77%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.text-reticencia a {
  text-overflow: ellipsis;
  overflow: hidden;
}


.container-breadcrump {
  display: flex;
  align-items: center;
  justify-content: center;

  padding-bottom: 20px;
}

/*Questoes*/
.questao-td-contador {
  font-family: monospace;
  font-size: 8px;
  width: 15px;
  padding-top: 6px;
  padding-right: 5px;
  text-align: right;
}

.questao-td-contador span {
  display: none;
}

.questao-table {
  @media (min-width: 769px) {
    display: flex;
  }
}

.questao-table tr:first-child .questao-td-contador span {
  display: inline;
}

.questao-table tr:nth-child(3n) .questao-td-contador span {
  display: inline;
}

.questao-autoria {
  font-style: italic;
  width: 590px;
  margin-top: 20px;
  @media (max-width: 769px) {
    width: 100%;
  }
}

.questao-td-texto {
  // text-indent: 1.5em;
  margin-top: 0;

  div {
    img {
      width: 100%;
    }
  }

  pre {
    white-space: pre-wrap; /* Since CSS 2.1 */
    white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
    white-space: -pre-wrap; /* Opera 4-6 */
    white-space: -o-pre-wrap; /* Opera 7 */
    word-wrap: break-Word; /* Internet Explorer 5.5+ */
  }
}

/*Fim Questoes*/

.retircencia-multipla {
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  display: -webkit-box;
  overflow: hidden;
  width: auto;
  text-overflow: ellipsis;
}

.footer-save {
  position: fixed;
  bottom: 0;
  width: 100%;

  display: flex;
  justify-content: space-between;

  height: 45px;
  background: #ffffff;
  border-top: 1px solid #000000;
  box-sizing: border-box;

  padding: 10px 20px;
  z-index: 99;

  > p {
    margin: 0;
    display: flex;
    align-items: center;
  }

  div {
    display: flex;
    align-items: center;

    p {
      margin: 0;
      padding-right: 20px;
    }
  }

  .btn-acoes-estruturar {
    margin-right: 50px;

    button {
      width: 50px;

      &:disabled {
        cursor: not-allowed;
      }
    }

    span {
      font-size: 14px;
    }

    div:nth-child(2) {
      padding: 0 25px;
    }

    @media (max-width: 1500px) {
      margin-right: 10px;
    }
  }
}

@media (min-width: 768px) {
  .c-main > .container-fluid {
    padding: 0;
    margin: 0;
  }
}

.questaoVF {
  font-size: 15px;

  background: rgb(195, 235, 223);
  padding: 10px 20px;

  .questaoVF-enunciado {
    background: #408bba;
    color: rgb(255, 243, 243);

    margin-top: 10px;
    padding: 10px 20px;

    border-radius: 2px;
    white-space: break-spaces;

    img {
      width: max-content;
    }
  }

  .questaoVF-alternativas {
    border-bottom: solid 1px rgb(155, 164, 250);

    padding: 5px 10px;
    margin-top: 3px;

    .spanNumero {
      background: #408bba;
      color: #fff;

      font-size: 12px;
      padding: 2px 15px;
      margin-right: 25px;
      border-radius: 5px;
    }

    .alternativaVf {
      background: #408bba;
      color: #fff;
      font-size: 12px;
      border-radius: 5px;
      padding: 10px;
      margin-bottom: 10px;
    }
  }
}

.close {
  position: absolute;
  right: 0;
  font-size: 1.563rem;
  font-weight: bold;

  color: #aaaaaa;

  padding: 10px 20px;

  cursor: pointer;
}

.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;

  cursor: pointer;
}

.link_estruturacao {
  cursor: pointer;

  &:hover {
    color: #343aeb;
  }
}

/* Context menu css */

nav.react-contextmenu {
  position: fixed;
  width: 250px;
  left: 0;
  top: calc(100% + 10px);
  border-radius: 4px;
  background-color: #fff;
  padding: 10px 0;
  z-index: 99;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
}

.react-contextmenu .react-contextmenu-item:hover {
  background: #cccccc;
}

.react-contextmenu-item:hover:not(.react-contextmenu-item--disabled) {
  background-color: #f1f1f1;
}

.react-contextmenu .react-contextmenu-item {
  font-size: 14px;
  display: block;
  text-decoration: none;
  padding: 10px 15px;
  cursor: pointer;
  user-select: none;
  transition: 0.2s;
  position: relative;
  margin-bottom: 2px;
  font-weight: 500;
  display: flex;
  align-items: center;
  outline: none;
}

.react-contextmenu-item span {
  margin-left: 10px;
}

.react-contextmenu-item svg {
  font-size: 14px;
}

.react-contextmenu-item .copy {
  color: skyblue;
}

.react-contextmenu-item .delete {
  color: red;
}

.react-contextmenu-item .send {
  color: blue;
}

.react-contextmenu-item .share {
  color: green;
}

.react-contextmenu-item .watchlist {
  color: rebeccapurple;
}

.predefined-themes-example .deni-react-treeview-container {
  width: 100%;
  height: 490px;
}

.disabledbutton {
  pointer-events: none;
  opacity: 0.4;
  cursor: not-allowed;
}

.select_problema_questao .select__control {
  width: 275px;
  margin: 20px auto;
  cursor: pointer;
}

.lote-comentario .ql-container.ql-snow {
  border: none;
  @media (max-width: 1500px) {
    height: 355px;
    overflow: auto;
  }
}

.lote-comentario .ql-disabled {
  background: #ededed;
  opacity: 0.5;
  cursor: not-allowed;
}

.lote-comentario .ql-container .ql-editor {
  @media (max-width: 1500px) {
    overflow-y: unset;
  }
}

.ql-container .ql-editor {
  min-height: 600px;
  max-height: 600px;
  font-size: 1rem;
  line-height: 1.5;

  @media (max-width: 1500px) {
    min-height: 406px;
    max-height: 406px;
  }

  span[aria-hidden = "true"] {
    display: none;
  }
}
