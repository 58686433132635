.lotes .form-select-customized {
  grid-template-columns: repeat(2, 1fr);

  .select-search:not(.is-disabled) .select-search__input {
    width: 90%;
  }
}

.customSelect {
  display: flex;
  align-items: center;
  margin-right: 15px!important;
}

.prova_lote .form-select-customized {
  grid-template-columns: none;

  .select-search:not(.is-disabled) .select-search__input {
    width: 100%;
  }

  input {
    height: 38px;
    border-radius: 4px;
    border: 0.5px solid;
    font-size: 13px;
    padding-left: 16px;
  }

  input:focus {
    outline: 0;
  }

  textarea {
    height: 38px;
    border-radius: 4px;
    border: 0.2px solid;
    font-size: 13px;
    padding: 8px 0px 0px 16px;
  }

  textarea:focus {
    outline: 0;
  }
}

.form-select-customized .select-padrao .q-select-input .dropdown-menu {
  padding-bottom: 0;
}

.divSelect {
  display: grid;
  grid-template-columns: repeat(3, 1fr) !important;
  grid-auto-rows: 56px;
  width: 100%;
}

.form-select-customized,
.prova-form-select-customized {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-auto-rows: 56px;
  width: 100%;

  &.grid5 {
    grid-template-columns: repeat(5, 1fr);
  }

  &.grid6 {
    grid-template-columns: repeat(6, 1fr);
  }

  small {
    text-transform: capitalize;
  }

  .show-validation-advice + .select-search {
    color: #768192;
    background-color: #fff;
    border-color: #958bef;
    outline: 0;
    box-shadow: 0 0 4px 2px #958bef;
  }

  .select-search {
    width: 100%;
    padding: 0px !important;

    ul {
      padding: 10px;

      // li {
      //   padding: 0px !important;
      // }
    }
  }

  .select-customizado {
    flex: 0 1 340px;
    margin: 0 0 0 1.875rem;

    @media (max-width: 769px) {
      width: 100%;
    }
  }

  .badge-radio,
  .checkbox,
  .radio {
    position: relative;
    display: -webkit-inline-flex;
    display: inline-flex;
    -webkit-align-items: center;
    align-items: center;
    margin-bottom: 0;
    padding-left: 42px;
    cursor: pointer;
  }

  @media (min-width: 768px) {
    .badge-radio,
    .checkbox,
    .radio {
      padding-left: 30px;
    }
  }

  .badge-radio input,
  .checkbox input,
  .radio input {
    display: none;
  }

  .badge-radio input:disabled ~ span,
  .checkbox input:disabled ~ span,
  .radio input:disabled ~ span {
    cursor: default;
    opacity: 0.5;
  }

  .badge-radio span,
  .checkbox span,
  .radio span {
    margin: 0;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    font-size: 14px;
  }

  @media (min-width: 768px) {
    .badge-radio span,
    .checkbox span,
    .radio span {
      font-size: 12px;
    }
  }

  @media (min-width: 768px) {
    .badge-radio span:after,
    .radio span:after {
      left: 4px;
      width: 9px;
      height: 9px;
      border-radius: 4.5px;
    }
  }

  .badge-radio input:checked ~ span:before,
  .radio input:checked ~ span:before {
    border-color: #575b5c;
    background-color: #1010a0;
  }

  .badge-radio input:checked ~ span:after,
  .radio input:checked ~ span:after {
    display: block;
  }

  .checkbox span:after,
  .checkbox span:before {
    content: "";

    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);

    display: inline-block;
    position: absolute;
    top: 20px;
  }

  .checkbox span:before {
    display: flex;
    justify-content: center;
    align-items: center;

    left: 0;
    width: 24px;
    height: 24px;

    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 3px;

    cursor: pointer;
  }

  .checkbox span:after {
    content: "";

    display: none;

    left: 0;

    width: 24px;
    height: 24px;

    text-align: center;
    font-size: 1.063rem;
    pointer-events: none;

    background-image: url("../assets/image/filtro/Checkbox.svg");
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: 20px 15px 0;
    // background-color: var(--blue-btn);
  }

  @media (min-width: 768px) {
    .checkbox span:after {
      font-size: 13px;
      line-height: 21px;
    }
  }

  .checkbox span:hover:before {
    border-color: rgba(0, 0, 0, 0.18);
  }

  .checkbox input:checked ~ span:before {
    border-color: #bce3ec;
    background-color: #321fdb;
  }

  .checkbox input:checked ~ span:after {
    display: inline-block;
    border-radius: 5px;
  }

  .checkbox.disabled label,
  .disabled.badge-radio label,
  .radio.disabled label,
  fieldset[disabled] .badge-radio label,
  fieldset[disabled] .checkbox label,
  fieldset[disabled] .radio label,
  fieldset[disabled] input[type="checkbox"],
  fieldset[disabled] input[type="radio"],
  input[type="checkbox"].disabled,
  input[type="checkbox"][disabled],
  input[type="radio"].disabled,
  input[type="radio"][disabled] {
    cursor: not-allowed;
  }

  .caret {
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 2px;
    vertical-align: middle;
    border-top: 4px dashed;
    border-top: 4px solid \9
  ;
    border-right: 4px solid rgba(0, 0, 0, 0);
    border-left: 4px solid rgba(0, 0, 0, 0);
  }

  .dropdown,
  .dropup {
    position: relative;
  }

  .dropdown-menu {
    position: absolute;
    z-index: 1000;
    top: 100%;
    left: 0;
    display: none;
    float: left;
    min-width: 160px;
    margin: 2px 0 0;
    padding: 5px 0;
    list-style: none;
    text-align: left;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 3px;
    background-color: #fff;
    background-clip: padding-box;
    box-shadow: 0 1px 5px -2px rgba(0, 0, 0, 0.05);
    font-size: 14px;
  }

  .dropdown-menu.pull-right {
    right: 0 !important;
    left: auto !important;
  }

  .dropdown-menu .divider {
    height: 1px;
    margin: 10px 0;
    overflow: hidden;
    background-color: rgba(0, 0, 0, 0.1);
  }

  .dropdown-menu > li > a {
    display: -webkit-flex;
    display: flex;
    clear: both;
    -webkit-align-items: center;
    align-items: center;
    padding: 7.5px 20px;
    white-space: nowrap;
    color: #495057;
    font-weight: 400;
    line-height: 1.618;
  }

  @media (max-width: 768px) {
    .dropdown-menu > li > a {
      padding: 12.5px 20px;
    }
  }

  .dropdown-menu > li > a i + span {
    margin-left: 0.5em;
    line-height: 1;
  }

  .dropdown-menu > li > a:focus,
  .dropdown-menu > li > a:hover {
    text-decoration: none;
    color: #343a40;
    background-color: #f1f3f5;
  }

  .dropdown-menu > .active > a,
  .dropdown-menu > .active > a:focus,
  .dropdown-menu > .active > a:hover {
    text-decoration: none;
    color: #35707d;
    outline: 0;
    background-color: #edf8ff;
  }

  .dropdown-menu > .disabled > a,
  .dropdown-menu > .disabled > a:focus,
  .dropdown-menu > .disabled > a:hover {
    color: #e9ecef;
  }

  .dropdown-menu > .disabled > a:focus,
  .dropdown-menu > .disabled > a:hover {
    filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
    cursor: not-allowed;
    text-decoration: none;
    background-color: rgba(0, 0, 0, 0);
    background-image: none;
  }

  .open > .dropdown-menu {
    display: block;
  }

  .hide > .dropdown-menu {
    display: none;
  }

  .open > a {
    outline: 0;
  }

  .select-search__input .spinner-border {
    color: var(--grey500);
    width: 15px;
    opacity: 0.5;
    height: 15px;
    margin-left: 10px;
    border-width: 2px;
  }

  .form-group {
    margin-bottom: 0;
    align-items: center;
  }

  .q-icon {
    display: inline-block;
    -webkit-transform: translate(0);
    transform: translate(0);
    text-rendering: auto;
    font: 400 normal 400 14px/1;
    font-size: 25px;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
  }

  .q-icon-search {
    svg {
      margin-bottom: 7px;
      width: 18px;
      height: 18px;
    }
  }

  .q-icon-times:before {
    content: "×";
  }

  .q-icon {
    font-weight: 400 !important;
    font-style: normal;
  }

  .q-select-input {
    position: relative;
  }

  .q-select-input .dropdown-toggle {
    position: relative;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
    width: 100%;
    padding-right: 35px;
    text-align: left;
    outline: none;
  }

  .q-select-input .dropdown-toggle.input-lg .caret {
    top: 18px;
  }

  .q-select-input .dropdown-toggle .q-placeholder {
    white-space: nowrap;
    overflow: hidden;
    -ms-text-overflow: ellipsis;
    text-overflow: ellipsis;
    max-width: 60vw;
    // color: var(--grey-text-dark);
    font-size: 14px;
  }

  .q-select-input .dropdown-toggle .caret {
    position: absolute;
    top: 18px;
    right: 15px;
    color: #000;
  }

  @media (min-width: 768px) {
    .q-select-input .dropdown-toggle .caret {
      top: 14px;
    }
  }

  .q-count {
    white-space: nowrap;
    overflow: hidden;
    -ms-text-overflow: ellipsis;
    text-overflow: ellipsis;

    margin-left: auto;
    font-size: 10px;
  }

  .q-select-input .dropdown-menu {
    position: static;

    top: auto;
    left: auto;

    width: auto;
    min-width: 100%;
    max-width: 100%;

    padding: 0 10px;
    border-width: 0;
    background-color: #fff;
    box-shadow: none;
  }

  @media (min-width: 768px) {
    .q-select-input .dropdown-menu {
      position: absolute;
      top: 100%;
      left: 0;
      width: 340px;
      max-width: 340px;
      padding-bottom: 20px;
      border-width: 1px;
      box-shadow: 0 6px 12px rgba(0, 0, 0, 0.12);
    }
  }

  .q-select-input .dropdown-menu .q-search-input {
    margin: 23px 12px;
    position: relative;
    padding: 0;
    background-color: rgba(0, 0, 21, 0.05);
  }

  .q-select-input .dropdown-menu .q-search-input > input {
    height: 35px;
    padding-right: 50px;
    padding-left: 35px;

    border: none;
    background-color: rgba(0, 0, 0, 0);
    box-shadow: none;
  }

  .q-select-input .dropdown-menu .q-search-input > .q-icon {
    display: inline-block;
    padding: 0 2px;
  }

  .q-select-input .dropdown-menu .q-search-input > .q-icon {
    position: absolute;
    top: 0;
    left: 10px;
  }

  .q-select-input .dropdown-menu .q-search-input .q-clear-btn {
    position: absolute;
    top: 0;
    right: 10px;
  }

  .q-select-input .dropdown-menu .q-search-input .q-clear-btn {
    display: none;

    right: 35px;
    margin: 0;
    padding: 0;
    border: none;
    outline: none;
    -webkit-appearance: none;

    color: #6d767e;
    background: rgba(0, 0, 0, 0);

    cursor: pointer;
  }

  .q-select-input .dropdown-menu .q-search-input .q-clear-btn:hover {
    color: #343a40;
  }

  .q-select-input .dropdown-menu .q-search-input:hover .q-clear-btn {
    display: inline-block;
  }

  .q-select-input .dropdown-menu .q-hidden-inputs {
    display: none;
  }

  .q-select-input .dropdown-menu .q-options {
    overflow: visible;
    overflow-y: auto;

    width: 95%;
    max-height: 250px;
    margin: 0;

    list-style: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  //scroll bar
  .q-select-input .dropdown-menu .q-options {
    &::-webkit-scrollbar {
      width: 5px;
    }

    &::-webkit-scrollbar-thumb {
      background: #8a9099;
      border-radius: 4px;
    }
  }

  .q-select-input .dropdown-menu .q-options .q-empty {
    padding: 15px 10px;
    text-align: center;
    font-size: 12px;
  }

  .q-select-input .dropdown-menu .q-options li {
    width: 100%;
    min-width: 100%;
    padding: 0 10px 0 26px;
    outline: none;

    @media (max-height: 640px) {
      padding: 0 10px 0 10px;
    }
  }

  .q-select-input .dropdown-menu .q-options li:focus,
  .q-select-input .dropdown-menu .q-options li:hover {
    background-color: #f1f3f5;
  }

  .q-select-input .dropdown-menu .q-options li:focus label,
  .q-select-input .dropdown-menu .q-options li:hover label {
    color: #343a40;
  }

  .q-select-input .dropdown-menu .q-options li label {
    width: 100%;
    margin: 0;
    padding-top: 13px;
    padding-bottom: 13px;
    cursor: pointer;
    word-break: break-all;
    word-break: break-word;
    color: #343a40;
  }

  @media (min-width: 768px) {
    .q-select-input .dropdown-menu .q-options li label {
      padding-top: 10px;
      padding-bottom: 10px;
    }
  }

  .q-select-input .dropdown-menu .q-options li.q-select-heading {
    padding: 10px 15px 10px 46px;
    pointer-events: none;
    color: #56606a;
    border-bottom: 1px solid rgba(8, 8, 8, 0.1);
    font-size: 12px;
    font-weight: 900;
  }

  @media (min-width: 768px) {
    .q-select-input .dropdown-menu .q-options li.q-select-heading {
      padding-left: 36px;
    }
  }

  .q-select-input .dropdown-menu .q-options li.q-select-heading:hover {
    background-color: rgba(0, 0, 0, 0);
  }

  .q-select-input .dropdown-menu .q-dropdown-footer {
    display: none;
  }

  .q-select-input-single .dropdown-menu li.selected,
  .q-select-input-single .dropdown-menu li.selected:hover {
    color: #35707d !important;
    background-color: #edf8ff;
  }

  .q-select-input-single .dropdown-menu li.selected:hover span,
  .q-select-input-single .dropdown-menu li.selected span {
    color: #35707d !important;
  }

  .q-select-input-single .dropdown-menu li .checkbox,
  .q-select-input-single .dropdown-menu li .radio {
    padding-right: 15px;
    padding-left: 15px;
  }

  .q-select-input-single .dropdown-menu li .checkbox span:after,
  .q-select-input-single .dropdown-menu li .checkbox span:before,
  .q-select-input-single .dropdown-menu li .radio span:after,
  .q-select-input-single .dropdown-menu li .radio span:before {
    display: none;
  }

  .q-select-input-single .dropdown-menu li {
    padding-right: 15px;
    padding-left: 15px;
  }

  .form-control:focus {
    box-shadow: none;
  }

  body.q-select-no-scroll {
    overflow: hidden;
  }

  .left-inner-search {
    position: relative;

    ::-webkit-input-placeholder {
      color: var(--grey500);
    }

    :-moz-placeholder {
      /* Firefox 18- */
      color: var(--grey500);
    }

    ::-moz-placeholder {
      /* Firefox 19+ */
      color: var(--grey500);
    }

    :-ms-input-placeholder {
      color: var(--grey500);
    }

    svg {
      width: 20px;
      height: 20px;
    }
  }

  .left-inner-search i {
    position: absolute;
    padding: 0.438rem 0.75rem;
    pointer-events: none;
    color: var(--grey500);
  }

  .left-inner-search input.form-control {
    padding-left: 2.5rem;
    border-color: transparent;
    background: var(--grey200);
    border-radius: 10px;
  }

  .q-options label.checkbox span {
    text-transform: capitalize;

    font-weight: normal;
    font-size: 0.813rem;
    line-height: 22px;
    color: var(--greyTextLight);
  }

  /**
   * Main wrapper
   */

  .select-search {
    width: 100%;
    position: relative;
    font-family: "Nunito Sans", sans-serif;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin: 0px;
  }

  .select-search *,
  .select-search *::after,
  .select-search *::before {
    box-sizing: inherit;
  }

  /**
   * Value wrapper
   */
  .select-search__value {
    position: relative;
    z-index: 1;

    border-radius: 15px;
    border: 1px solid #000000;
  }

  .select-search__value::after {
    content: "";
    display: inline-block;
    position: absolute;
    top: calc(50% - 5px);
    right: 19px;
    width: 7px;
    height: 7px;
  }

  /**
   * Input
   */
  .select-search__input {
    display: block;

    height: 36px;
    width: 100%;
    padding: 0 15px 0 16px;

    background: #fff;
    border: 1px solid #000000;

    outline: none;
    font-size: 12px;
    text-align: left;
    text-overflow: ellipsis;
    line-height: 36px;
    -webkit-appearance: none;

    font-weight: normal;
  }

  .filter {
    width: 247px !important;
  }

  .select-search__input::-webkit-search-decoration,
  .select-search__input::-webkit-search-cancel-button,
  .select-search__input::-webkit-search-results-button,
  .select-search__input::-webkit-search-results-decoration {
    -webkit-appearance: none;
  }

  .select-search__input:not([readonly]):focus {
    cursor: initial;
  }

  .select-search__input > svg {
    margin-left: 10px;
  }

  /**
   * Options wrapper
   */
  .select-search__select {
    background: #fff;
    box-shadow: 0 0.0625rem 0.125rem rgba(0, 0, 0, 0.15);
  }

  /**
   * Options
   */

  .select-search {
    .select-search__options {
      list-style: none;
      width: 100%;
      padding: 0;
      margin: 0;
      display: none;
    }

    &.has-focus {
      .select-search__options {
        display: block;
      }
    }
  }

  /**
   * Option row
   */
  .select-search__row:not(:first-child) {
    border-top: 1px solid #eee;
  }

  /**
   * Option
   */
  .select-search__option,
  .select-search__not-found {
    display: block;
    height: 36px;
    width: 100%;
    padding: 0 16px;
    background: #fff;
    border: none;
    outline: none;
    font-family: "Noto Sans", sans-serif;
    font-size: 14px;
    text-align: left;
    cursor: pointer;
  }

  .select-search--multiple .select-search__option {
    height: 48px;
  }

  .select-search__option.is-selected {
    background: #2fcc8b;
    color: #fff;
  }

  .select-search__option.is-highlighted,
  .select-search__option:not(.is-selected):hover {
    background: rgba(47, 204, 139, 0.1);
  }

  .select-search__option.is-highlighted.is-selected,
  .select-search__option.is-selected:hover {
    background: #2eb378;
    color: #fff;
  }

  /**
   * Group
   */
  .select-search__group-header {
    font-size: 10px;
    text-transform: uppercase;
    background: #eee;
    padding: 8px 16px;
  }

  /**
   * States
   */
  .select-search.is-disabled {
    opacity: 0.5;
  }

  .select-search.is-loading .select-search__value::after {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='50' height='50' viewBox='0 0 50 50'%3E%3Cpath fill='%232F2D37' d='M25,5A20.14,20.14,0,0,1,45,22.88a2.51,2.51,0,0,0,2.49,2.26h0A2.52,2.52,0,0,0,50,22.33a25.14,25.14,0,0,0-50,0,2.52,2.52,0,0,0,2.5,2.81h0A2.51,2.51,0,0,0,5,22.88,20.14,20.14,0,0,1,25,5Z'%3E%3CanimateTransform attributeName='transform' type='rotate' from='0 25 25' to='360 25 25' dur='0.6s' repeatCount='indefinite'/%3E%3C/path%3E%3C/svg%3E");
    background-size: 11px;
  }

  .select-search:not(.is-disabled) .select-search__input {
    display: flex;
    justify-content: space-between;
    align-items: center;

    width: 247px;
    margin: 0;
  }

  /**
   * Modifiers
   */
  .select-search--multiple {
    border-radius: 3px;
    overflow: hidden;
  }

  .select-search:not(.is-loading):not(.select-search--multiple) .select-search__value::after {
    transform: rotate(45deg);
    border-right: 1px solid #000;
    border-bottom: 1px solid #000;
    pointer-events: none;
  }

  .select-search--multiple .select-search__input {
    cursor: initial;
  }

  .select-search--multiple:not(.select-search--search) .select-search__input {
    cursor: default;
  }

  .select-search:not(.select-search--multiple) .select-search__input:hover {
    border-color: #2fcc8b;
  }

  .select-search:not(.select-search--multiple) .select-search__select {
    position: absolute;
    z-index: 2;
    top: 44px;
    right: 0;
    left: 0;
    border-radius: 3px;
    overflow: auto;
    max-height: 360px;
  }

  .select-search--multiple .select-search__select {
    position: relative;
    overflow: auto;
    max-height: 260px;
    border-top: 1px solid #eee;
    border-radius: 0 0 3px 3px;
  }

  .select-search__not-found {
    height: auto;
    padding: 1rem;
    text-align: center;
    color: #888;
  }

  .select-search:not(.select-search--multiple) .select-search__input:hover {
    border-color: #000000;
  }

  .select-search.is-loading:not(.select-search--multiple) .select-search__value::after {
    transform: rotate(45deg);
    border-right: 1px solid #000;
    border-bottom: 1px solid #000;
    pointer-events: none;
    background-image: inherit;
  }

  .close-select-search {
    background: none;
    border: none;
    position: absolute;
    top: 31px;
    right: 60px;
    z-index: 1;
    text-align: center;
    justify-content: center;
    align-items: center;
    padding: 0px;
    margin: 0px;
    display: none;
    animation: fadein 0.5s;
    -moz-animation: fadein 0.5s; /* Firefox */
    -webkit-animation: fadein 0.5s; /* Safari and Chrome */
    -o-animation: fadein 0.5s; /* Opera */
    svg {
      margin: 0px;
      padding: 0px;
    }

    &:active,
    &:focus {
      border: none;
      outline: none;
    }

    &.show {
      display: flex;
    }

    @keyframes fadein {
      from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
    }
    @-moz-keyframes fadein {
      /* Firefox */
      from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
    }
    @-webkit-keyframes fadein {
      /* Safari and Chrome */
      from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
    }
    @-o-keyframes fadein {
      /* Opera */
      from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
    }
  }
}

.prova-form-select-customized {
  display: flex;

  .select-search:not(.is-disabled) .select-search__input {
    width: 100%;
  }
}
